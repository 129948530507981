import {LicenseManager} from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
	'CompanyName=XPO Logistics (Boston, MA),LicensedGroup=Enterprise Architecture,' +
	'LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,' +
	'LicensedProductionInstancesCount=0,AssetReference=AG-014247,' +
	'ExpiryDate=16_April_2022_[v2]_MTY1MDA2MzYwMDAwMA==f8235caadbde24c608a1217bd9809d38'
);

(window as any).process = {
	env: { DEBUG: undefined },
  };

import('./bootstrap')
	.catch(err => console.error(err));
